#calculator {
    padding: 20px;
    width: 500px;
    margin: auto;
    margin-top: 30px;
    box-shadow: 0 0px 20px #ffaa00;
    border: 2px solid #ffaa00;
    border-radius: 2%;
    text-align: center;
}

.input-box-slider {
    margin-bottom: 30px;
}

.input-box-slider .title {
    margin-bottom: 5px;
}

.input-box-slider .description {
    margin-top: 8px;
}

.slider-input {
    display: flex;
    justify-content: center;
    height: 25px;
    margin-bottom: 5px;
}

.slidecontainer {
    width: 50%;
    margin-left: 10px;
    margin-right: 10px;
}
  
.slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 5px;
    background: #ffaa00;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}
  
.slider:hover {
    opacity: 1;
}
  
.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #ffaa00;
    border-radius: 50%;
    cursor: pointer;
}

.slider-label {
    width: 25%;
    color: lightgray;
    line-height: 25px;
}

.slider-label.left {
    text-align: right;
}

.slider-label.right {
    cursor: pointer;
    text-align: left;
}

.slider-value {
    font-size: 20px;
}


.label-value-list {
    margin: 0;
    padding-left: 0;
}

.label-value-list > li {
    margin-bottom: 5px;
    list-style-type: none;
}

.label-value-list.small-text > li {
    margin-bottom: 0;
}

.label-value-list > li > span {
    margin-left: 8px;
}

.input-box-wrap {
    display: flex;
    justify-content: center;
}

.input-box-wrap .button {
    height: unset;
    min-width: unset;
    font-size: unset;
    font-size: 12px;
}

.input-box {
    width: 150px;
    font-family: "FONT";
    font-size: 18px;
    color: white;
    padding: 5px;
    background-color: #030d14;
    border: 2px solid #ffaa00;
    border-radius: 5px;
}

.input-box:focus {
    outline: none;
    box-shadow: 0 0px 10px #ffaa00;
}



@media only screen and (max-width: 600px) {
    #calculator {
        width: 100%
    }

    .slidecontainer {
        width: 70%;
    }
}