#statistics .chart {
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 20px;
    box-shadow: 0px 0px 20px #071B28;
    border-radius: 5px;
    background-color: #071B28;
}

#statistics .buttons {
    display: block;
    text-align: right;
    margin-bottom: 10px;
}

.buttons .button {
    margin-left: 10px;
    height: unset;
    min-width: 50px;
    font-size: unset;
    font-size: 12px;
}

#statistics > .glowing-tabs {
    margin-bottom: 50px;
}

.glowing-tabs > ul {
    gap: 30px;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.glowing-tabs li {
    text-align: center;
    width: 200px;
    padding: 20px;
    list-style-type: none;
    border: 2px solid #fa0;
    border-radius: 5px;
    box-shadow: 0 0 15px #fa0;
}

.glowing-tabs li .value {
    font-size: 26px;
    color: #fa0;
    text-shadow: 0 0 15px #fa0;
}

.table-wrap {
    margin-top: 40px;
    overflow: auto;
    box-shadow: 0px 0px 20px #071b28;
    border-radius: 5px;
    background-color: #071B28;
    padding: 20px;
}

.table-wrap .table-top {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.table-wrap a {
    color: lightgray;
}

.table-wrap table {
    width: 100%;
    border-collapse: collapse;
    border-style: hidden;
}

.table-wrap th, .table-wrap td {
    border: 2px dotted #030d14;
}

.table-wrap th, .table-wrap td { 
    padding: 5px;
    padding-left: 10px;
}

.table-wrap th {
    position: relative;
    text-align: left;
    color: #fa0;
}

.table-wrap td {
    min-width: 100px;
    font-size: 12px;
    color: lightgray;
}

.table-wrap tr:not(:first-child):hover {
    background-color: #030d14;
}

.table-wrap p {
    text-align: center;
}

.table-wrap th > i {
    z-index: 0;
    position: absolute;
    right: 10px;
    font-size: 20px;
}

.table-wrap .button {
    margin-left: 10px;
    height: unset;
    min-width: 50px;
    font-size: unset;
    font-size: 12px;
}