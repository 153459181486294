@font-face {
    font-family: "FONT";
    src:  url("https://cdn.glitch.com/a654e63b-496c-4135-a627-fb8e3b38591b%2FRusso_One.ttf?v=1561405270231");
}

@keyframes spin {
	0% {transform: rotate(0deg); }
	100% {transform: rotate(360deg); }
}

.loader {
    margin: auto;
	width: 25px;
	height: 25px;
  
    border: 0.15em solid rgba(15,15,25,1);
    border-right: 0.15em solid rgba(15,15,25,1);
    border-left: 0.15em solid rgba(15,15,25,1);
    border-radius: 5px;
  
    animation: spin 1s ease infinite;
}

* {
    box-sizing: border-box;
    font-weight: 100;
}

html, 
body {
    height: 100%;
    cursor: default;
}

body {
    font-family: "FONT";
    width:100%;
    margin:0;
    padding:0;
    color: white;
}

#main {
    max-width: 1000px;
    padding: 25px;
    margin: auto;
}

.light-bg {
    background-color: #071B28;
}

.dark-bg {
    background-color: #030d14;
}

.dark-text {
    color:#030d14;
}

.light-text {
    color: #071B28;
}

.small-text {
    color: lightgray;
    font-size: 12px;
}

.highlight-text {
    color: #ffaa00;
}


.button {
    cursor: pointer;
    min-width: 140px;
    font-family: "FONT";
    color: #030d14;
    height: 50px;
    padding: 8px;
    font-size: 20px;
    background-color: #ffaa00;
    border-radius: 5px;
    border: 2px solid #ffaa00;
}

.button.disabled {
    color: gray;
    cursor: not-allowed;
}

.button.small {
    min-width: 70px;
    height: 35px;
    padding: 5px;
    border-radius: 0;
}

.button:hover {
    color: #ffaa00;
    background-color: rgba(0,0,0,0);
}

.button:hover > .loader {
    border-color: #ffaa00;
}

.button.active {
    color: #ffaa00;
    background-color: rgba(0,0,0,0);
}

.wrong {
    color: red!important;
}

.glowing {
    text-shadow: 0px 0px 15px #ffaa00;
}

#main h1 {
    margin-bottom: 40px;;
}

@media only screen and (max-width: 600px) {
}