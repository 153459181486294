#header a {
    text-decoration: none;
    color: white;
}

#header a:hover {
    text-decoration: none;
    color: #ffaa00;
}

#header li {
    list-style-type: none;
}

#header {
    height: 80px;
    box-shadow: 0 5px 20px #000;
}

#header > div {
    display: flex;
    height: 100%;
    max-width: 1000px;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
}

#header-right {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    margin-left: auto;
}

#header-right > li {
    cursor: pointer;
    margin-left: 20px;
}


@media only screen and (max-width: 600px) {
    #header {
        overflow: hidden;
    }

    #header > div {
        padding-top: 10px;
        padding-bottom: 10px;
        display: block;
        width: 100%;
    }

    #header > div > a > img {
        margin: auto;
        display: block;
        width: 50%;
    }

    #header-right {
        margin-top: 5px;
        padding: 0;
        justify-content: center;
    }
}